<template>
  <v-container fluid>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="11">
        <v-col class="d-flex justify-space-between align-center">
          <h1 class="secondary--text">Configurar Layout</h1>
        </v-col>
      </v-col>
    </v-row>

    <v-row v-if="isFinancialGroup" justify="center">
      <v-col cols="12" md="11">
        <v-row class="mx-0">
          <v-col cols="12" md="3">
            <label class="label">Grupo Financeiro*</label>
            <v-autocomplete
              :items="financialGroups"
              v-model="layout.financialGroupId"
              class="mt-2"
              item-text="name"
              item-value="id"
              item-color="textPrimary"
              placeholder="Selecione o grupo financeiro"
              color="textPrimary"
              solo
              disabled
            />
          </v-col>
          <v-col cols="12" md="3">
            <label class="label">Contrato</label>
            <v-autocomplete
              :items="contracts"
              v-model="layout.contractId"
              class="mt-2"
              item-text="text"
              item-value="id"
              item-color="textPrimary"
              placeholder="Selecione o contrato"
              color="textPrimary"
              solo
              :loading="loadingContracts"
              @input="loadSubContractsByContractId()"
            />
          </v-col>
          <v-col cols="12" md="3">
            <label class="label">Sub-Contrato</label>
            <v-autocomplete
              :items="subContracts"
              v-model="layout.subContractId"
              class="mt-2"
              item-text="text"
              item-value="id"
              item-color="textPrimary"
              placeholder="Selecione o sub-contrato"
              color="textPrimary"
              solo
              :loading="loadingSubContracts"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="isInsuranceCarrier" justify="center">
      <v-col cols="12" md="11">
        <v-col cols="12" md="3">
          <label class="label">Operadora*</label>
          <v-autocomplete
            :items="insuranceCarriers"
            v-model="layout.insuranceCarrierId"
            class="mt-2"
            item-text="xipp_commercial_name"
            item-value="id"
            item-color="textPrimary"
            placeholder="Selecione o operadora"
            color="textPrimary"
            solo
            disabled
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-0">
      <v-col cols="12" md="11">
        <v-col>
          <v-btn-toggle v-model="selectedTab" mandatory dense>
            <div style="background-color: var(--v-background-base)">
              <v-btn
                small
                active-class="primary"
                min-width="158"
                class="mr-6"
              >
                Modelos Existentes
              </v-btn>
              <v-btn
                small
                active-class="primary"
                min-width="158"
                @click="createLayout()"
                class="mr-6"
              >
                Criar Layout
              </v-btn>
            </div>
          </v-btn-toggle>
        </v-col>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-col>
          <h3 class="secondary--text">Buscar por modelos existentes</h3>
        </v-col>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-row class="mx-0">
          <v-col cols="12" md="3">
            <label class="label">Nome</label>
            <v-text-field
              v-model="layoutFilterName"
              label="Nome"
              maxlength="70"
              class="mt-2"
              placeholder="Busque por nome"
              solo
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <label class="label">Categoria</label>
            <v-autocomplete
              v-model="layoutFilter.tags"
              :items="layoutTags"
              item-text="description"
              item-value="description"
              solo
              label="Busque por categoria"
              @change="requestLayoutModels()"
              class="mt-2"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-row class="mx-0">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="layoutModels"
              :page.sync="page"
              :loading="loadingLayoutModels"
              hide-default-footer
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:[`item.layoutType`]="{ item }">
                <span>{{ item.layoutType === 'NONE' ? 'Nenhum' : item.layoutType === 'POSITIONAL' ? 'Posicional' : item.layoutType === 'DELIMITED' ? 'Delimitado' : null }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="goToVisualizationLayoutModel(item)" class="mr-5" color="primary" v-bind="attrs" v-on="on">
                      <v-icon> visibility </v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar Modelo</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :loading="loadingButtomUse" depressed small @click="useLayoutModel(item)" color="primary" v-bind="attrs" v-on="on">
                      Utilizar
                    </v-btn>
                  </template>
                  <span>Utilizar Modelo</span>
                </v-tooltip>
              </template>
            </v-data-table>

            <div class="float-right mt-2">
              <v-pagination
                color="textPrimary"
                v-model="page"
                :length="totalPages"
                :total-visible="9"
                @input="requestLayoutModels()"
              />
            </div>
          </v-col>
          <v-col cols="12" align="end">
            <v-footer>
              <v-row class="d-flex pt-6 justify-end">
                <v-btn
                  x-large
                  outlined
                  color="textPrimary"
                  width="250px"
                  @click="onClickBack()"
                >
                  Voltar
                </v-btn>
              </v-row>
            </v-footer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
import LayoutTagService from '@/services-http/sdi/LayoutTagService';
import ContractService from '@/services-http/contract/ContractService';
import LayoutService from '@/services-http/sdi/LayoutService';
import LayoutColumnService from '@/services-http/sdi/LayoutColumnService';
import { mapGetters, mapMutations } from 'vuex';

export default ({
  data: () => ({
    layoutType: {},
    financialGroups: [],
    contracts: [],
    subContracts: [],
    insuranceCarriers: [],
    layoutTags: [],
    layoutModels: [],
    layout: {
      financialGroupId: null,
      insuranceCarrierId: null,
      contractId: null,
      subContractId: null,
    },
    layoutFilter: {
      name: null,
      tags: null,
    },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    loadingButtomUse: false,
    overlay: false,
    isFinancialGroup: false,
    isInsuranceCarrier: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingLayoutModels: false,
    selectedTab: 0,
    page: 1,
    totalPages: 0,
    search: null,
    timeout: null,
    headers: [
      {
        text: 'Modelos Disponíveis',
        align: 'start',
        value: 'name',
        class: 'table-header',
        sortable: false,
      },
      {
        text: 'Tipo de Arquivo',
        align: 'center',
        value: 'fileType',
        class: 'table-header',
        sortable: false,
      },
      {
        text: 'Tipo de Layout',
        align: 'center',
        value: 'layoutType',
        class: 'table-header',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        class: 'table-header',
        sortable: false,
      },
    ],
  }),

  mounted() {
    sessionStorage.removeItem('visualization');
    this.loadSessionStorage();
    this.loadTags();
    this.requestLayoutModels();
  },

  computed: {
    ...mapGetters({
      layoutData: 'layout-import/layout',
      layoutTypeData: 'layout-import/layoutType',
    }),
    layoutFilterName: {
      get() {
        return this.layoutFilter.name;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.layoutFilter.name = val;
          this.requestLayoutModels();
        }, 500);
      },
    },
  },

  methods: {
    ...mapMutations({
      setLayoutType: 'layout-import/setLayoutType',
      setLayout: 'layout-import/setLayout',
    }),
    loadSessionStorage() {
      // this.layoutType = JSON.parse(sessionStorage.getItem('layoutType'));
      this.layoutType = { ...this.layoutTypeData };
      this.financialGroups = this.layoutType && this.layoutType.financialGroups.length > 0 ? this.layoutType.financialGroups : [];
      this.insuranceCarriers = this.layoutType && this.layoutType.insuranceCarriers.length > 0 ? this.layoutType.insuranceCarriers : [];
      this.layout.financialGroupId = this.layoutType && this.layoutType.financialGroupId ? this.layoutType.financialGroupId : null;
      this.layout.insuranceCarrierId = this.layoutType && this.layoutType.insuranceCarrierId ? this.layoutType.insuranceCarrierId : null;

      if (this.layout && this.layout.financialGroupId) {
        this.isFinancialGroup = true;
        this.loadContractsByFinancialGroupId();
      } else {
        this.isInsuranceCarrier = true;
      }
    },
    requestLayoutModels() {
      this.loadingLayoutModels = true;
      this.layoutService.FindAllByFilter({
        isModel: true,
        page: this.page - 1,
        size: 5,
        name: this.layoutFilter.name,
        tags: this.layoutFilter.tags,
      }).then((response) => {
        if (response && response.data.content.length > 0) {
          this.layoutModels = response.data.content;
          this.layoutModels = this.layoutModels.sort((a, b) => (a.id > b.id ? -1 : 1));
          this.totalPages = response.data.totalPages;
        }
        this.loadingLayoutModels = false;
      }).catch(() => {
        this.loadingLayoutModels = false;
      });
    },
    loadLayoutModels() {
      this.loadingLayoutModels = true;
    },
    async loadContractsByFinancialGroupId() {
      if (this.layout.financialGroupId) {
        this.loadingContracts = true;
        this.contracts = [];
        this.subContracts = [];
        const query = `financialGroupIds=${this.layout.financialGroupId}`;
        await this.contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data) {
            const contracts = response.data.filter((c) => c.subcontract === false);
            this.contructorContractDescription(contracts);
          }
          this.loadingContracts = false;
        }).catch(() => {
          this.loadingContracts = false;
        });
      }
    },
    async loadSubContractsByContractId() {
      if (this.layout.contractId) {
        this.loadingSubContracts = true;
        this.subContracts = [];
        await this.contractService.FindByParentId(this.layout.contractId).then((response) => {
          if (response && response.data) {
            const subContracts = response.data.filter((c) => c.subcontract === true);
            this.contructorSubContractDescription(subContracts);
          }
          this.loadingSubContracts = false;
        }).catch(() => {
          this.loadingSubContracts = false;
        });
      }
    },
    contructorContractDescription(contracts) {
      if (contracts) {
        this.contracts = contracts.map((item) => ({
          ...item,
          text: (`${(item.benefit_id && item.benefit_id.name ? item.benefit_id.name : item.benefit_id ? item.benefit_id : '-')} - ${(item.carrier_id && item.carrier_id.xipp_commercial_name ? item.carrier_id.xipp_commercial_name : '-')} - ${item.policy ? item.policy : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
        }));
        this.layoutType.contracts = this.contracts;
      }
    },
    contructorSubContractDescription(subContracts) {
      if (subContracts) {
        this.subContracts = subContracts.map((item) => ({
          ...item,
          text: (`${item.subcontract_number ? item.subcontract_number : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
        }));
        this.layoutType.subContracts = this.subContracts;
        this.layoutType.subContracts.sort((a, b) => a.subcontract_number.localeCompare(b.subcontract_number, { numeric: true }));
      }
    },
    createLayout() {
      sessionStorage.setItem('redirectToImport', JSON.stringify(true));
      this.redirectRouter('create');
    },
    loadTags() {
      this.layoutTagService.FindAll().then((response) => {
        if (response && response.data) {
          this.layoutTags = response.data;
        }
      });
    },
    async goToVisualizationLayoutModel(item) {
      if (item) {
        this.layout = item;
        await this.requestLayoutModel();
        sessionStorage.setItem('visualization', JSON.stringify(true));
        this.redirectRouter('visualization');
      }
    },
    async useLayoutModel(item) {
      if (item) {
        this.layout = {
          ...item,
          financialGroupId: this.layout.financialGroupId,
          insuranceCarrierId: this.layout.insuranceCarrierId,
          contractId: this.layout.contractId,
          subContractId: this.layout.subContractId,
        };
        await this.requestLayoutModel();
        this.redirectRouter('useLayout');
      }
    },
    async requestLayoutModel() {
      this.overlay = true;
      const query = `?id_layout=${this.layout.id}`;
      await this.layoutColumnService.FindAllByFilter(query).then((response) => {
        if (response && response.data.content.length > 0) {
          this.layout.columns = response.data.content[0].columns;
          // sessionStorage.setItem('layoutType', JSON.stringify(this.layoutType));
          // sessionStorage.setItem('layout', JSON.stringify(this.layout));
          this.setLayoutType(this.layoutType)
          this.setLayout(this.layout)
        }
        this.overlay = false;
      }).catch(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao localizar as colunas do layout');
      });
    },
    onClickBack() {
      this.redirectRouter('back');
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    redirectRouter(type) {
      if (type === 'create') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'InitialConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'InitialConfigs' });
        sessionStorage.removeItem('layout');
      } else if (type === 'back') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImports', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImports' });
      } else if (type === 'visualization') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'ColumnEditConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'ColumnEditConfigs' });
      } else if (type === 'useLayout') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImportColumnConfigs' });
      }
    },
  },

  created() {
    this.layoutService = new LayoutService();
    this.contractService = new ContractService();
    this.layoutTagService = new LayoutTagService();
    this.layoutColumnService = new LayoutColumnService();
  },
});
</script>
